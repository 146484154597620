.class_head_pic {
    margin-left: 10px;
    margin-right: 15px;
    width: 40px;
    height: 40px;
}

.class_container {
    display: flex;
    line-height: 66px;
    align-items: center;
    transition: background-color 0.05s;
}

.class_container:hover {
    background-color: rgba(216, 215, 215, 0.3);
}

.class_main_container.active {
    background-color: rgba(200, 198, 199, 0.6);
}
