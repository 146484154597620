.console_page_container {
    height: 100%;
    background: white;
    display: flex;
}

.console_page_left {
    width: 72px;
    height: 100%;
    background-color: rgb(64, 65, 66);
}

.head_pic {
    width: 36px;
    height: 36px;
    /*padding-top: 24px;*/

}

.head-pic-container {
    display: flex;
    justify-content: center;
}

.cloud_disk_page_container_iframe {
    border: none;
    width: 100%;
    height: 100%;
}

.cloud_disk_page_container {
    width: calc(100% - 72px);
    height: calc(100% - 10px);
}
