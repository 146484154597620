html, body, div, h1, h2, h3, h4, h5, h6, p, span,input,section,ol, ul {
    padding:0;
    margin: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color:transparent;
    background: transparent;
  }

  input{
    border:0;
    outline: none;
  }

  html,body{
    width: 100%;
    height: 100%;
    /*text-align: center;*/
    -webkit-user-select: none;/*禁用手机浏览器的用户选择功能 */
    -moz-user-select: none;
    margin:0 auto;
  }

  button {
    font-family: Arial-BoldMT, Arial;
  }

  body *{
    -webkit-text-size-adjust: none;/* 禁用浏览器的文本溢出算法，解决移动端触发webkit内核浏览器的Font Boosting */
    -ms-text-size-adjust:none;
  }

  ol,ul {
    list-style: none;
  }

  img {
    height: 100%;
  }
