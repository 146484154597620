.msg-container {
    position: absolute;
    top: 15px;
    width: 100%;
}

.msg-body {
    display: flex;
    justify-content: center;
}
