.rc-time-picker-panel-select-option {
    position: relative;
}

.rc-time-picker-panel-select-option::after {
    content: '小时';
    position: absolute;
    right: 10px;
    color: #999;
    font-size: 12px;
}

.rc-time-picker-panel-select-minute .rc-time-picker-panel-select-option::after {
    content: '分钟';
}

/* 自定义样式 */
.custom-select .ant-select-selection-item {
    text-align: left !important;
}
