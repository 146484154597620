.head-t {
    /*width: 240px;*/
    /*height: 180px;*/
    /*background-color: black;*/
    border: 6px solid rgb(10, 192, 249);
    border-radius: 6px;
    /*margin-left: 5px;*/
    /*margin-bottom: 10px;*/
    /*margin-right: 10px;*/
    /*margin-top: 20px;*/

    background-color: black;
    /*border: 6px solid #ff4d4f;*/
    /*border-radius: 6px;*/
    /*margin-left: -5px;*/
    /* margin-bottom: 10px; */
    /* margin-right: 5px; */
    margin-top: 10px;
}

.head-s {
    width: 240px;
    height: 180px;
    /*background-color: black;*/
    border: 6px solid rgb(10, 192, 249);
    border-radius: 6px;
    /*margin-left: 5px;*/
    /*margin-bottom: 10px;*/
    /*margin-right: 10px;*/
    /*margin-top: 20px;*/

    background-color: black;
    /*border: 6px solid #ff4d4f;*/
    /*border-radius: 6px;*/
    /*margin-left: -5px;*/
    /* margin-bottom: 10px; */
    /* margin-right: 5px; */
    margin-bottom: 10px;
}

#local_video {
    width: 100%;
    height: 100%;
    position: relative;
}

.videoContainerStyle_up {
    width: calc(100% - 40px);
    height: calc(100%);
    position: relative;
    background-color: rgb(91, 101, 212);
    margin: auto;
    border-radius: 10px;
    background-color: rgb(60,60,60);
    border: 2px solid rgb(60,60,60);

    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
}

.content-bottom-bar {
    height: 48px;
    width: 100%;
    background-color: rgb(91, 101, 212);
}

.overlay-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 280px;
    height: 220px;
    /* 使图片不可点击 */
    pointer-events: none;
}

.student-content-container {
    display: flex;
    flex-direction: column;
    width: calc(100% - 280px);
    height: 100%;
}

.student-live-page-container {
    display: flex;
    flex-direction: row;
    height: 100%;
    background-color: rgb(91, 101, 212);
}

.student-right-container {
    width: 280px;
    height: 100%;

    position: relative;
}

.head-container {
    position: relative;
}

.student-right-head-container {
    position: relative;
    width: 280px;
    height: 220px;
}

.tab_content_s {
    width: 100%;
    height:calc(100% - 440px);
    flex-grow: 1;
    background-color: rgb(79, 89, 184);
    display: flex;
    flex-direction: column;


    position: absolute;
    right: 0px;
    border-radius: 10px;

    margin-right: 10px;
    margin-bottom: 10px;
    overflow: hidden;
}

.chat-only-teacher {
    margin: auto;
    /* height: 100%; */
    margin-right: 6px;
}

.chat-only-text {
    margin: auto;
    margin-right: 10px;
}

.chat-room-title-text {
    flex-grow: 1;
}

.chat-room-title-text-s {
    margin: auto;
    margin-left: 10px;
    text-align: left;
    flex-grow: 1;
}
