.top-rtc-s {
    height: 160px;
    display: flex;
    background-color: rgb(91, 101, 212);
}

.video-container-s {
    width: 160px;
    height: 120px;
    border: 4px solid rgb(10, 192, 249);
    border-radius: 6px;
    margin-left: 20px;
    margin-bottom: 10px;
    margin-right: 30px;
    margin-top: 20px;
}

.video_rtc {
    width: 100%;
    height: 100%;
}
