@font-face {

}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 16px !important;
  font-family: Arial-BoldMT, Arial !important;
  font-weight: normal;

  background-color: transparent;
  transform-origin: 0 0;

  overflow: overlay;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* 全局样式覆盖滚动条样式 */
/* 滚动条整体部分 */
::-webkit-scrollbar {
  /*width: 8px; !* 垂直滚动条的宽度 *!*/
  /*height: 8px; !* 水平滚动条的高度 *!*/
}

/* 滚动条轨道 */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* 滚动条轨道的背景颜色 */
}

/* 滚动条的滑块 */
::-webkit-scrollbar-thumb {
  background: #888; /* 滚动条滑块的背景颜色 */
  border-radius: 4px;
}

/* 当鼠标悬停在滑块上 */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* 滚动条滑块的背景颜色 */
}
