 .custom-popover .ant-popover-arrow {
    display: none;
}

 .custom-popover-content .ant-btn {
     border: none;
 }

 .custom-popover-content .ant-btn {
     border: none;
     transition: none !important;
     max-height: 25px;
     box-shadow: none !important;
 }

 .custom-popover .ant-popover-inner {
     width: 300px; /* 设置弹出框的宽度 */
 }

 .picker-item:hover {
     background-color: lightgray;
 }

 .picker-footer {
     display: flex;
     flex-direction: row-reverse;
     margin-top: 10px;
 }

 /* 移除 Button 的动画效果 */
 .no-animation-button.ant-btn {
     transition: none !important;
 }

 .no-animation-button.ant-btn::after {
     display: none !important;
 }

 /* 移除 Button 选中时的动画效果 */
 .no-animation-button.ant-btn:focus,
 .no-animation-button.ant-btn:hover,
 .no-animation-button.ant-btn:active {
     transition: none !important;
     box-shadow: none !important;
 }

 /* styles.css */
 .no-click-effect {
     position: relative;
     overflow: hidden;
 }

 .no-click-effect::after {
     display: none !important;
 }

 .no-click-effect:active {
     background-color: #096dd9; /* 按下时的背景颜色 */
     border-color: #096dd9; /* 按下时的边框颜色 */
 }

 .no-click-effect-button:focus {
     background-color: #096dd9; /* 选中时的背景颜色 */
     border-color: #096dd9; /* 选中时的边框颜色 */
 }
