.home_page_left {
    width: 350px;
    height: 100%;
    border-width: 1px;
    border-right-style: solid;
    border-right-color: rgba(0, 0, 0, 0.1);
    /*margin-top: -20px;*/
    display: flex;
    flex-direction: column;
}

.create_bar_container {
    width: 350px;
    height: 80px;
    background-color: white;
    /*box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.1);*/
    display: flex;
    justify-content: space-around;
    align-items: center;
    /*border-top: 1px solid lightgray;*/

    /*margin-left: 20px;*/
    /*margin-right: 20px;*/
    /*box-sizing: border-box;*/
}

.bar_line {
    margin-top: 40px;
    border: 1px solid rgba(0, 0, 0, 0.08);
}

.home_page_container {
    display: flex;
    flex-grow: 1;
}

.home_page_right {
    flex-grow: 1;
    text-align: left;
    text-align: left;
    display: flex;
    flex-direction: column;
}

.home_page_title {
    text-align: left;
    font-size: 20px;
    padding-left: 15px;
    border-bottom: 1px solid lightgray;
    padding-bottom: 10px;
}

.home_page_class_info_container {

}

.home_page_kejie {
    padding: 10px;

}

.home_page_kejie_list {
    overflow: auto;
    flex-grow: 1;
}

.home_page_bottom {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
}

.home_page_class_list {
    overflow: auto;
    flex-grow: 1;
}

/* 覆盖 AntD Modal 标题的默认样式 */
.ant-modal-title {
    text-align: left;
}

.class_normal {
    text-align: left;
    margin-bottom: 10px;
    margin-top: 20px;
    font-size: 14px;
    font-weight: bold;
}

.dialog_line {
    background-color: lightgray;
    height: 1px;
}

.search-container {
    display: flex;
    flex-direction: row;
    margin: 10px;
}

.add-button {
    font-size: 32px;
    color: rgba(99, 99, 99, 0.2);
}

.home_icon_container {
    display: flex;
    margin: auto;
}
