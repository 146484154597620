#head_img {
    width: 32px;
    height: 32px;
    margin-right: 10px;
}

#head_name {
   margin-right: 10px;
}

#info_container {
    margin-left: 10px;
    display: flex;
    align-items: flex-start;
    margin-top: 10px;
}

#info_text {
    text-align: left;
    background: transparent;
    margin-left: 60px;
    margin-top: -8px;
    border-radius: 5px;
    margin-right: 40px;
}

.msg-base-container {
    margin-bottom: 25px;
    color: rgb(200, 200, 200);
}

.msg-head-img {
    border-radius: 15px;
}
