.disable-animation-progress .ant-progress-inner {
    transition: none !important;
}

.disable-animation-progress .ant-progress-text {
    transition: none !important;
}

.main_container {
    background: white;
    padding: 10px;
}

.tbutton {
    margin: 10px;
    width: 160px;
}

.tool_btn {
    display: flex;
    /*justify-content: flex-end;*/
    justify-content: center;
}

.spacer {
    flex-grow: 1;
}

.section_av {
    display: flex;
    justify-content: space-between;
}

.section_left {
    width: 60%;
    display: flex;
    flex-direction: column;
}

.section_right {
    width: 40%;
    margin-right: 10px;
}

.device-empty-container {
    margin-top: 100px;
    display: flex;
    justify-content: center;
}
