.join-class-search-container {
    display: flex;
    margin: 10px;
}

.join-class-container {
    width: 100%;
    height: 100%;
}

.class-info {
    border-radius: 5px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    height: 500px;
    overflow: auto;
    width: 400px;
    margin: auto;
}

.class-info-title {
    height: 100px;
    background-color: #00a4ff;
    width: 400px;
    border-top-left-radius: 5px; /* 左上角圆角 */
    border-top-right-radius: 5px; /* 右上角圆角 */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.empty-container {
    text-align: center;
}
