.progress-bar {
    height: 6px;
    background-color: #ccc;
    overflow: hidden;
    width: calc(100% - 100px);
    display: inline-block;
}

.progress {
    height: 100%;
    background-color: orangered;
    transition: width 0.1s ease-in-out; /* 添加过渡动画 */
}
