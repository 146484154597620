.bar_item_name {
    font-size: 10px;
    color: rgb(190, 192, 193);
    margin-top: 0px;
}

.bar_item_name_selected {
    color: #07c160;
}

.bar_item_pic {
    width: 32px;
    height: 32px;
    padding-top: 8px;
}

.bar_item_container {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
}

.bar_item_left_line {
    width: 2px;
}

.bar_item_flex {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bar_item_line_selected {
    background: #07c160;
}
