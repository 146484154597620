.push_mask {
    width: 100%;
    position: absolute;
    height: 30px;
    z-index: 100;
    bottom: 0;

    display: flex;
    flex-direction: column-reverse;
}

.push_mask_bar {
    height: 35px;
    background-color: rgba(100, 100, 100, 0.3);
    display: flex;

    margin: 5px;
    border: 1px solid rgba(100, 100, 100, 0.4);
    border-radius: 5px;
}
