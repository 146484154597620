.login_left {
    float: left;
    height: 420px;
    width: 280px;
}

.login_left_pic {
    width: 100%;
    height: 400px;
}

.login_right {
    margin-left: 320px;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 40px;
}

.login_container {
    height: 100%;
    width: 100%;
    background-color: white;
    overflow: hidden;
}

.right_item {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.space_item {
    flex-grow: 1;
}
