.top_rtc {
    height: 160px;
    display: flex;
    background-color: rgb(60, 60, 60);
}

.video-container {
    width: 200px;
    height: 155px;
}

.video_rtc {
    width: 100%;
    height: 100%;
}
