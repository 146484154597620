.dialog_line {
    background-color: lightgray;
    height: 1px;
}

.h-space-item {
    width: 40px;
}

.margin-left-10 {
    margin-left: 10px;
}

.margin-right-10 {
    margin-right: 10px;
}
