.class_item_main_container {
    margin: 15px;
    background: rgba(99, 99, 99, 0.03);
    border-radius: 5px;
    padding: 5px 5px 5px 0;

    display: flex;
    height: 80px;
}

.course-order-container {
    background: #00a4ff;
    color: white;
    height: 20px;
    text-align: center;
    width: 40px;
    border-radius: 0 5px 5px 0;
}

.course-vlay-container {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.class-state {
    width: 90px;
    height: 100%;
    background-color: rgb(73, 111, 240);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}
