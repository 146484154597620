.t-container{
    display: flex;
    flex-direction: row;
    /* align-content: space-between; */
    justify-content: space-between;
}

.t-container-item {
    flex-grow: 1;
}
